import { AnalyticsEventType } from '@prisma/client';
import { genericAuthRequest, RuntimeBackend, useMailberryMutate } from '../base';

export function useTrackDemoEvent() {
  return useMailberryMutate<{}, { event: AnalyticsEventType; metadata?: Record<string, any>; value?: number; }>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/demo/tracking`, userData, RuntimeBackend);
    },
  });
}

import axios from 'axios';
export async function trackDemoEvent(accessToken: string, data: Record<string, any>) {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'accessToken': accessToken,
      },
    };
    const url = process.env.NEXT_PUBLIC_RUNTIME_API_HOST + '/api/0.0.1/demo/tracking';
    const response = await axios.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error('Error :', error.response || error.message);
    throw error;
  }
}
