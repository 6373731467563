import { useTrackDemoEvent } from '@/services/api/demo/tracking';
import DemoTabsMenu from '@components/screens/TabsMenu/DemoTabsMenu';
import { Box, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTour } from '@reactour/tour';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar } from './ui/mailberry/Navbar';

const DemoLayout = ({ children }: { children: React.ReactNode; }) => {
  let location = useLocation();
  const isMobileView = useMediaQuery('(max-width: 48em)');

  const showBackArrow = location.pathname !== '/demo';
  const { setCurrentStep, setIsOpen, currentStep, isOpen } = useTour();
  const trackDemoEvent = useTrackDemoEvent();
  const scrollPosition = useRef(0);
  const hasTrackedStep3 = useRef(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const currentScrollTop = event.currentTarget.scrollTop;

    if (currentScrollTop > scrollPosition.current) {
      if (scrollPosition.current !== 0 && currentStep === 2 && !hasTrackedStep3.current) {
        hasTrackedStep3.current = true;
        setTimeout(async () => {
          setCurrentStep(3);
          await trackDemoEvent.mutateAsync({ event: 'DemoStep', value: 3 })
            .catch(e => console.error(e));
        }, 3000);
      }
    }
    scrollPosition.current = currentScrollTop;
  };

  // reset on restart demo
  useEffect(() => {
    if (currentStep === 0) {
      hasTrackedStep3.current = false;
    }
  }, [currentStep]);

  return (
    <Stack onScroll={() => console.log(8)} w='100%' maw={'1000px'} gap={0} style={{ height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )' }}>
      <Navbar logoRedirect='/demo' showBackArrow={showBackArrow} isMobileView={isMobileView} />
      <Box
        onScroll={handleScroll}
        style={{ flexGrow: 2, grow: 2, borderBottom: '1px solid #E5E7EB', alignContent: 'center', overflow: 'scroll' }}
      >
        <div
          className={'tour-wrapper'}
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            width: '1px',
            height: '1px',
          }}
        >
        </div>
        {children}
      </Box>
      {isMobileView && !(location.pathname.startsWith('/demo/chat') || location.pathname.startsWith('/demo/review')) && <DemoTabsMenu />}
    </Stack>
  );
};

export default DemoLayout;
